<template>
  <div class="polygon v-wrapper tool--none">
    <!-- 电子围栏 -->
    <div class="v-nav">
      <div class="v-nav-polygon">
        <div class="v-title">
          {{$t('regionManage.totalTip', {count: regionList.length})}}
          <el-checkbox v-model="containChild">{{$t('regionManage.includeChild')}}</el-checkbox>
        </div>
        <div class="v-search">
          <el-input class="risk-polygon-query" :placeholder="$t('onlineMonitor.favoriteRegionTip')" v-model="queryKey"
            clearable></el-input>
        </div>
        <ul class="v-list">
          <li :class="{'active': activeRegionId == row.PolygonID}" v-for="row in tableList" :key="row.PolygonID"
            @click="() => rowClick(row)">
            <div class="content">
              <p class="region__name" :title="$t('regionManage.bindCountTip', {count: row.DeviceCount})">
                {{row.PolygonName}}[{{row.PolygonID}}]</p>
              <p class="region__desc" v-if="false">已绑定{{row.DeviceCount}}台设备</p>
            </div>
            <div class="option" @click.stop>
              <i-drop-down trigger="click" :show-text="$t('common.operate')">
                <i class="el-icon-more-outline" style="font-size:14px;width:16px;margin-top:5px;" slot="active"></i>
                <i-drop-down-option :label="$t('regionManage.modify')" value="0" @click="modifyRegion(row)">
                </i-drop-down-option>
                <i-drop-down-option :label="$t('regionManage.deflete')" value="1" @click="deleteRegion(row)">
                </i-drop-down-option>
                <i-drop-down-option :label="$t('regionManage.viewDetail')" value="3" @click="openDialogList(row)">
                </i-drop-down-option>
                <i-drop-down-option :label="$t('regionManage.contactRegion')" value="3" @click="openRegionObject(row)">
                </i-drop-down-option>
                <i-drop-down-option :label="$t('regionManage.cancelContact')" value="4" @click="openDialogList(row)">
                </i-drop-down-option>
              </i-drop-down>
            </div>
          </li>
        </ul>
        <el-pagination class="v-pager" v-if="regionList.length > 8 && regionList.length != 0" background
          layout="prev, jumper, next" :current-page.sync="table.pageIndex" :page-size="table.pageSize"
          :total="regionList.length">
        </el-pagination>
        <div v-if="regionList.length <=8 && regionList.length != 0" class="imy-1 caption"
          style="text-align:center;color: #B0BEC5;">{{$t('common.noMoreResults')}}</div>
        <div class="v-button">
          <el-button type="primary" plain @click="addRegion" class="border">
            {{$t('regionManage.add')}}</el-button>
        </div>

      </div>
    </div>
    <div class="v-content-polygon">
      <div class="container" ref="container"></div>


      <!-- <template v-if="showDrawDialog">
        <div class="v-draw-tip" :class="{'minimize':isMinimize}">
          <div class="tips"> {{ (modifyRow && !isMinimize) ? $t('regionManage.clearTip') : $t('regionManage.addTip')}}
            <transition name="scale-shrink">
              <div v-if="isDrawEnd" class="flat-btn" style="padding: 0 4px">
                <el-button @click="modifyDraw" class="border" type="primary" plain>{{ $t('regionManage.editPoly')}}
                </el-button>
                <el-button @click="startDraw" class="border" type="primary" plain>{{ $t('regionManage.drawAgain')}}
                </el-button>
              </div>
              <div class="flat-btn ipx-0" style="padding: 0 4px" v-else>
                <el-button v-if="isEditing" @click="endEdit" class="border" type="primary" plain>
                  {{ $t('regionManage.endEdit')}}</el-button>
              </div>
            </transition>
          </div>
          <div class="closeDraw" v-if="!modifyRow">
            
          </div>
          <div class="v-btn-group" v-if="!modifyRow">
            <span>开始</span>
          </div>
          <template v-else>
            <div class="v-btn-group">
              <span @click="startDraw">{{$t('regionManage.drawAgain')}}</span>
              <span @click="modifyDraw" class="iml-5">{{$t('regionManage.editPoly')}}</span>
            </div>
          </template>
        </div>
      </template> -->

      <select-car class="choose" @select="carSelect"></select-car>
      <!--区域车辆-->
      <transition name="fade-polygon">
        <div class="fixedTop" v-if="showBindObject" :key="activePolygon.PolygonID">
          <div class="fixedTopHeader">
            {{activePolygon.PolygonName}}
            <span class="fixedTopHeaderClose">
              <i class="el-icon-refresh" @click="refreshBindObject"></i>
              <i class="el-icon-close" @click="showBindObject = false"></i>
            </span>
          </div>
          <div class="vehicle-split"></div>
          <ul v-if="activePolygon.list.length">
            <li v-for="(item,index) in activePolygon.list.slice(0, 10)" :key="index">
              <span>
                {{item.VehicleName}}<span class="IOType">{{$t('regionManage.ioTypeList')[item.IOType] || '--'}}</span>
              </span>
              <p class="SIM2">
                {{item.SIM2}}
                <span class="Speed"
                  v-if="item.CheckSpeed">({{$t('regionManage.limitTip2', {speed: item.MaxSpeed})}})</span>
              </p>
            </li>
          </ul>
          <p class="nodata" v-else>
            {{$t('regionManage.nodata')}}
          </p>
          <p class="viewAll" v-if="activePolygon.list.length" @click="openDialogList(activePolygon)">
            {{$t('regionManage.viewAll')}}
          </p>
        </div>
      </transition>
    </div>
    <!-- 弹窗--关联设备详情 -->
    <detailPolyon ref="dialogPolyon" @openPolygonlist="openDialogList"></detailPolyon>

    <el-dialog append-to-body custom-class="v-dialog v-dialog--md v-dialog--mdpadding v-dialog--footer"
      :visible.sync="dialogListVisible" :title="$t('regionManage.vehiceBindList')">
      <el-table style="width: 100%" :data="dialogList">
        <el-table-column :label="$t('regionManage.vehicleName')" prop="VehicleName" width="120">
          <p slot-scope="{row}">
            {{row.VehicleName || row.SIM}}
          </p>
        </el-table-column>
        <el-table-column :label="$t('regionManage.deviceID')" prop="SIM2" width="110"></el-table-column>
        <el-table-column :label="$t('regionManage.lineType')" width="115">
          <template slot-scope="{row}">
            <span style="color:#3F51B5;">{{row.TrackerType==0?$t('dict')['有线设备']:$t('dict')['无线设备']}}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('regionManage.triggerAlarm')" width="120">
          <template slot-scope="{row}">
            <p v-if="row.NeedAlarm" style="color:#4CAF50">{{$t('regionManage.yes')}}</p>
            <p v-else>{{$t('regionManage.no')}}</p>
          </template>
        </el-table-column>
        <el-table-column :label="$t('regionManage.recordType')" width="260">
          <template slot-scope="{row}">
            <p>{{$t('regionManage.ioTypeList')[row.IOType] || '--'}} / <span
                style="color:#3F51B5">{{row.StartTime}}-{{row.EndTime}}</span></p>
          </template>
        </el-table-column>
        <el-table-column :label="$t('regionManage.regionLimit')" width="90">
          <template slot-scope="{row}">
            <p v-if="row.CheckSpeed">{{$t('regionManage.yes')}} / <span
                style="color:#f44336">{{$t('regionManage.limitTip2', {speed: row.MaxSpeed})}}</span></p>
            <p v-else>{{$t('regionManage.no')}}</p>
          </template>
        </el-table-column>
        <el-table-column min-width="65">
          <p slot-scope="{row}" class="i-link" @click="deleteRegionObject(dialogListRegionId, row.ObjectID)">
            {{$t('regionManage.jiechu')}}</p>
        </el-table-column>
      </el-table>
      <div slot="footer">
        <el-button @click="dialogListVisible=false">{{$t('common.close')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {
    mapGetters
  } from 'vuex'
  import {
    getDefaultMap
  } from '@/common/cache'
  import mixinMapCachePosition from '@/views/mixins/mixin-map-cache-position'
  import miment from 'miment'
  import * as API from '@/api/region'
  import IDropDown from '@/base/i-dropdown/i-dropdown'
  import IDropDownOption from '@/base/i-dropdown/i-dropdown-option'
  import MapPlace from '@/views/common/map-place'
  import SelectCar from '@/views/common/select-car'
  import SelectDevice from '@/views/common/select-device'
  import TreeUser from '@/views/common/tree-user'
  import detailPolyon from '@/views/risk/detail/detail-polygon'
  import {
    debounce,
    remoteLoad,
    deleteFromArray
  } from '@/common/utils'
  export default {
    name: 'riskPolygon',
    data() {
      return {
        regionList: [], // 围栏列表
        containChild: false,
        queryKey: '',
        activeRegionId: null,
        table: {
          pageSize: 8,
          pageIndex: 1
        },
        showBindObject: false, // 显示围栏信息
        activePolygon: {
          PolygonID: 0,
          PolygonName: '',
          list: []
        },
        dialogListVisible: false, // 关联设备弹窗
        dialogList: [],

        modifyRow: null, // 待编辑围栏对象
        map: null,
        showDrawDialog: false, // 显示编辑
      }
    },
    mixins: [
      mixinMapCachePosition
    ],
    components: {
      IDropDown,
      IDropDownOption,
      TreeUser,
      MapPlace,
      SelectCar,
      SelectDevice,
      detailPolyon
    },
    computed: {
      tableList() {
        return this.regionList.slice(this.table.pageSize * this.table.pageIndex - this.table.pageSize, this.table
          .pageSize * this.table.pageIndex)
      },
      ...mapGetters([
        'currentMap'
      ])
    },
    watch: {
      containChild() {
        this.fetchRegionList()
      },
      queryKey() {
        this.d_queryHandle()
      },
      currentMap(val) {
        console.log('当前地图' + val)
      }
    },
    methods: {
      async initMap(center = [116.397428, 39.90923], zoom = 11) {
        const position = this.loadPosition() // mixin
        center = position.center
        zoom = position.zoom

        if (!window.AMap) {
          await remoteLoad(this.$AMAP_URL)
        }

        if (!window.AMapUI) {
          await remoteLoad('//webapi.amap.com/ui/1.0/main.js?v=1.0.11')
        }

        let map = new window.AMap.Map(this.$refs.container, {
          resizeEnable: true,
          zoom,
          center
        })

        this.map = map
        this.AMap = window.AMap
        this.AMapUI = window.AMapUI
        // await this.initLayer()
        // await this.initRealScene()
      },
      openRegionObject(row) {
        this.activeName = 'second'
        this.modifyRow = row
        this.dialogVisible = true
        this.form.regionId = row.PolygonID
        this.searchCheck = '';
        this.searchState = '';
      },
      async deleteRegion(row) {
        try {
          await this.$confirm(this.$t('regionManage.deleteTip', {
            name: row.PolygonName
          }), {
            confirmButtonText: this.$t('common.confirm'),
            cancelButtonText: this.$t('common.cancel'),
            type: 'warning'
          })
        } catch (err) {
          return
        }
        API.DeleteRegion(row.PolygonID).then(ret => {
          if (!ret.errCode) {
            this.$message({
              message: this.$t('regionManage.deleteSuccessed'),
              type: 'success'
            })
            deleteFromArray(this.regionList, k => {
              return k.PolygonID === row.PolygonID
            })
          } else {
            this.$message({
              message: ret.errMsg,
              type: 'error'
            })
          }
        })
      },
      rowClick(row) { // 点击显示围栏
        this.activeRegionId = row.PolygonID
        this.addRegionToMap(row)
        this.addBindObject(row)
      },
      addRegionToMap(row, map) { // 添加围栏到地图
        map = map || this.map
        this.removeRegionFromMap()
        let polygon = new this.AMap.Polygon({
          map,
          path: row.Points.map(j => [j.Lon, j.Lat]),
          strokeColor: '#1890ff',
          strokeOpacity: '0.9',
          fillColor: '#1890ff',
          fillOpacity: '0.1'
        })
        this.polygon = polygon
        map.setFitView()
      },
      removeRegionFromMap() { // 删除地图所有围栏信息
        this.polygon && this.polygon.setMap(null)
      },
      modifyRegion(row) { // 修改围栏（菜单）
        // this.modifyRow = row
        // this.dialogVisible = true
        // this.form.regionId = row.PolygonID
        // this.searchCheck = '';
        // this.searchState = '';
        this.$refs.dialogPolyon.open()
      },
      addRegion() { // 添加围栏（菜单）
        this.showDrawDialog = true
      },
      editObj(obj, map) {
        map = map || this.map
        window.AMap.plugin(['AMap.PolyEditor'], () => {
          let msTool = new window.AMap.PolyEditor(map, obj)
          msTool.open()
          msTool.on('end', ({
            target
          }) => {
            this.points = target.getPath()
            this.polygon = target
          })
          this.msTool = msTool
        })
      },
      endEdit() {
        this.isEditing = false
        this.isDrawEnd = true
        this.msTool.close()
        this.msTool = null
      },
      modifyDraw() {

      },
      startDraw() {
        this.isMinimize = true
        this.isDrawEnd = false
        this.tabMap.clearMap()

        if (this.mousetool) {
          this.mousetool.polygon({
            strokeColor: '#1890ff',
            strokeOpacity: '0.9',
            fillColor: '#1890ff',
            fillOpacity: '0.1'
          })
          return
        }

        this.tabMap.plugin(['AMap.MouseTool'], () => {
          let mousetool = new this.AMap.MouseTool(this.tabMap)
          mousetool.polygon({
            strokeColor: '#1890ff',
            strokeOpacity: '0.9',
            fillColor: '#1890ff',
            fillOpacity: '0.1'
          })
          mousetool.on('draw', ({
            obj
          }) => {
            this.isDrawEnd = true
            const points = obj.getPath()
            this.points = points
            this.polygon = obj
            mousetool.close()
          })
          this.mousetool = mousetool
        })
      },

      carSelect(lon, lat, name) {
        if (!lon) {
          this.$message(this.$t('onlineMonitor.noPositionTip'))
          return
        }
        if (this.carMarker) {
          this.carMarker.setMap(null)
          this.carMarker = null
        }
        this.map.setCenter([lon, lat])
        this.map.setZoom(16)
        this.marker = []
        this.carMarker = new window.AMap.Marker({
          position: [lon, lat],
          map: this.map,
          title: name
        })
        this.marker = [lon, lat]
      },
      addBindObject(row) {
        this.activePolygon.PolygonName = row.PolygonName
        this.activePolygon.PolygonID = row.PolygonID
        API.GetRegionObject(row.PolygonID).then(({
          data
        }) => {
          this.activePolygon.list = data.slice()
          this.showBindObject = true
        })
      },
      refreshBindObject() {
        if (!this.activePolygon.PolygonID) {
          return
        }
        API.GetRegionObject(this.activePolygon.PolygonID).then(({
          data
        }) => {
          this.activePolygon.list = data.slice()
          this.showBindObject = true
        })
      },
      async openDialogList(row) {
        console.log(row)
        this.dialogListRegionId = row.PolygonID
        await this.fetchRegionObjectList()
        this.dialogListVisible = true
      },
      deleteRegionObject(regionId, objectid) {
        const list = [objectid]
        API.DeleteRegionObject(regionId, list).then(ret => {
          if (!ret.data.errCode) {
            const index = this.dialogList.findIndex(k => k.ObjectID === objectid)
            if (index != -1) {
              this.dialogList.splice(index, 1)
            }
          }
        })
      },
      fetchRegionObjectList() {
        if (!this.dialogListRegionId) {
          this.dialogList = []
          return
        }
        const promise = new Promise((resolve) => {
          API.GetRegionObject(this.dialogListRegionId).then(({
            data
          }) => {
            this.dialogList = data.slice()
            resolve()
          })
        })
        return promise
      },
      fetchRegionList() {
        const promise = new Promise((resolve, reject) => {
          API.GetRegionList(this.containChild, this.queryKey).then(ret => {
            this.table.pageIndex = 1
            this.regionList = ret.data
            resolve()
          }).catch(err => {
            reject(err)
          })
        })
        return promise
      },
    },
    created() {
      console.log(getDefaultMap())
      this.fetchRegionList()
      this.IOTypeList = this.$t('regionManage.ioTypeList')
      this.d_queryHandle = debounce(this.fetchRegionList, 300)
    },
    mounted() {
      this.initMap()
    }
  }

</script>

<style lang="scss" scoped>
  @import '../../styles/mixin.scss';
  @import "../../styles/variables-simple.scss";
  @include v-wrapper;
  @include v-area;
  @include v-item;

  .v-nav {
    @include scroll-bar-grey;
  }

  .v-nav-polygon {
    width: 250px;

    .v-title {
      padding: 20px 20px 20px 20px;
      border-bottom: 1px solid #dcdfe6;
      color: #606266;
      font-size: 14px;

      .el-checkbox {
        float: right;
      }
    }

    .v-search {
      padding: 8px;

      .risk-polygon-query ::v-deep input {
        border-radius: 20px;
        transition: all .3s ease;

        &:focus {
          background-color: #F4F4F8;
          box-shadow: 0 1px 3px 0 rgba(44, 34, 243, 0.25);
          border-color: transparent;
        }
      }
    }

    .v-list {
      li {
        font-size: 12px;
        padding: 8px 12px 8px 20px;
        margin-left: 8px;
        margin-right: 8px;
        margin-bottom: 8px;
        border-radius: 20px;
        border: 1px solid #dcdfe6;
        display: -ms-flexbox;
        display: flex;
        cursor: pointer;

        .content {
          -ms-flex-positive: 1;
          flex-grow: 1;

          .region__name {
            font-size: 14px;
            color: #606266;
            line-height: 22px;
          }
        }

        .option {
          width: 30px;

          .i-dropdown {
            padding-top: 0;
            padding-bottom: 0;
          }

        }
      }

      .active {
        border: 1px solid #1890ff;
        background-color: #e8f4ff;
      }

    }

    .v-button {
      margin-top: 8px;
      padding: 0 8px;

      .el-button {
        width: 100%;
        border-radius: 20px;
      }
    }

  }

  .v-content-polygon {
    width: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    position: relative;

    .container {
      height: 100%;
    }

    .choose {
      position: absolute;
      top: 20px;
      left: 50px;
      z-index: 1;
    }

    .v-draw-tip {
      position: absolute;
      z-index: 111;
      top: 25%;
      left: 25%;
      height: 50%;
      width: 50%;
      transition: all .3s ease;
      background-color: rgba(64, 158, 255, .8);
      border-radius: 4px;

      .tips {
        color: #fafafa;
        height: 38px;
        line-height: 38px;
        padding-left: 12px;

        .flat-btn {
          float: right;
        }
      }

      .v-btn-group {
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 12px 24px;
        font-size: 18px;
        color: #fff;
        transition: all .3s ease;
        cursor: default;

      }
    }

    .minimize {
      top: 0;
      left: 0;
      height: 38px;
      width: 100%;
    }

    .fixedTop {
      position: absolute;
      width: 350px;
      border-radius: 4px;
      padding: 12px 24px 24px;
      top: 60px;
      right: 24px;
      background-color: rgba(255, 255, 255, .9);
      border: 1px solid rgba(0, 0, 0, .24);

      .fixedTopHeader {
        height: 32px;
        line-height: 32px;
        color: rgba(0, 0, 0, .86);
        font-size: 16px;

        .fixedTopHeaderClose {
          float: right;

          i {
            font-size: 16px;

            &:hover {
              color: rgba(0, 0, 0, 1);
              cursor: pointer;
            }
          }

          i+i {
            margin-left: 12px;
          }
        }
      }

      .vehicle-split {
        margin: 4px auto 12px;
        height: 1px;
        background-color: rgba(0, 0, 0, .24);
        width: 302px;
      }

      ul {
        list-style-position: inside;
        margin-bottom: -8px;

        li {
          font-size: 14px;
          margin-bottom: 8px;

          .IOType {
            color: $--color-primary;
            float: right;
            font-size: 12px;
          }

          .SIM2 {
            margin: 4px 0 0 20px;
            color: rgba(0, 0, 0, .56);

            .Speed {
              font-size: 12px;
            }
          }
        }
      }

      .nodata {
        height: 40px;
        line-height: 40px;
        color: rgba(0, 0, 0, .54);
        font-size: 14px;
        text-align: center;
        margin-top: 24px;
      }

      .viewAll {
        font-size: 14px;
        margin: 16px 0 0 20px;
        color: $--color-primary;
        cursor: pointer;
      }
    }

    /*fade*/
    .fade-polygon-enter-active,
    .fade-polygon-leave-active {
      transition: all 0.28s ease-out;
    }

    .fade-polygon-enter,
    .fade-polygon-leave-active {
      opacity: 0;
      transform: translateY(30px);
    }

  }

</style>
